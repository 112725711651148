const Home = () => {
    return (
        <div>
            <div className="banner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center align-items-start">
                            <div className="content">
                                <h1 className="text-danger">
                                    <i className="fa fa-play-circle"></i> Your Online Coding Hub
                                </h1>
                                <p className="text-black">
                                    Welcome to CodeRecordz, your ultimate destination for mastering the art of coding! Our carefully curated courses cater to all skill levels, from beginners to seasoned developers. Unlock your coding potential today!
                                </p>
                                <input type="text" className="form-control" placeholder="What Would You Like To Learn?" />
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            {/* You can add a visual element here, like an image or a graphic */}
                        </div>
                    </div>
                </div>

                {/* Decorative lines on the banner */}
                <div className="line" style={{ left: "5%" }}></div>
                <div className="line" style={{ left: "15%" }}></div>
                <div className="line" style={{ left: "25%" }}></div>
                <div className="line" style={{ left: "35%" }}></div>
                <div className="line" style={{ left: "45%" }}></div>
                <div className="line" style={{ left: "55%" }}></div>
                <div className="line" style={{ left: "65%" }}></div>
                <div className="line" style={{ left: "75%" }}></div>
                <div className="line" style={{ left: "85%" }}></div>
                <div className="line" style={{ left: "95%" }}></div>
            </div>

            <div className="container-fluid">
                <div className="jumbotron p-5 mx-auto">
                    <div className="row">
                        <div className="col-md-4  d-flex align-items-start">
                            <div className="me-3">
                                <i className="icon fa fa-book"></i>
                            </div>
                            <div>
                                <h5>Unlimited Access</h5>
                                <p className="fs-5">Get unlimited access to over 3,000 curated coding courses, from beginner to expert level.</p>
                            </div>
                        </div>
                        <div className="col-md-4  d-flex align-items-start">
                            <div className="me-3">
                                <i className="icon fa fa-list"></i>
                            </div>
                            <div>
                                <h5>Customized Learning Path</h5>
                                <p>Select the courses that align with your career goals and personal learning pace.</p>
                            </div>
                        </div>

                        <div className="col-md-4 d-flex align-items-start">
                            <div className="me-3">
                                <i className="icon fa fa-user-plus"></i>
                            </div>
                            <div>
                                <h5>Career Growth</h5>
                                <p>Upgrade your skills with real-world coding projects and build your portfolio to impress employers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid my-2">
                <h2 className="mb-4">Featured <font color="red">Courses</font></h2>
                <div id="cardCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000" data-bs-pause="hover">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 1" />
                                        <div className="card-body">
                                            <h5 className="card-title">Web Development</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 2" />
                                        <div className="card-body">
                                            <h5 className="card-title">Data Science</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 3" />
                                        <div className="card-body">
                                            <h5 className="card-title">Machine Learning</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 4" />
                                        <div className="card-body">
                                            <h5 className="card-title">Artificial Intelligence</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 5" />
                                        <div className="card-body">
                                            <h5 className="card-title">Cybersecurity</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 6" />
                                        <div className="card-body">
                                            <h5 className="card-title">Mobile App Development</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 7" />
                                        <div className="card-body">
                                            <h5 className="card-title">Cloud Computing</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="images/course.png" className="card-img-top" alt="Course 8" />
                                        <div className="card-body">
                                            <h5 className="card-title">Blockchain</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="jumbotron bg-danger mt-4 p-5 text-white text-center">
                <h2>Learn Online, Earn Online</h2>
                <p>
                    Gain the skills and knowledge you need to advance in the tech industry. Our platform offers everything from beginner-level tutorials to advanced developer tools. Ready to take your career to the next level? Join us today!
                </p>
                <button className="btn btn-warning">
                    <i className="fa fa-play-circle"></i> Explore Courses
                </button>
            </div>

            <div className="container my-5">
                <div className="row text-center">
                    <div className="col-md-3">
                        <div className="card shadow-lg p-5">
                            <i className="fa fa-users fa-5x text-danger mb-3"></i>
                            <h3 className="fw-bold count">234</h3>
                            <p className="text-muted">Students Enrolled</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow-lg p-5">
                            <i className="fa fa-desktop fa-5x text-danger mb-3"></i>
                            <h3 className="fw-bold count">235</h3>
                            <p className="text-muted">Top Courses</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow-lg p-5">
                            <i className="fa fa-globe fa-5x text-danger mb-3"></i>
                            <h3 className="fw-bold count">323</h3>
                            <p className="text-muted">Countries Represented</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow-lg p-5">
                            <i className="fa fa-trophy fa-5x text-danger mb-3"></i>
                            <h3 className="fw-bold count">342</h3>
                            <p className="text-muted">Awards Received</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5">
                        <img src="images/social.png" alt="Social Proof" />
                    </div>
                    <div className="col-lg-7 p-5">
                        <h2><font color="red"><i className="fa fa-list"></i> Course Builder is Proudly Brought to You.</font></h2>
                        <p>
                            Whether you're a beginner or a professional, we have the right course for you. Expand your skills and unlock new career opportunities. From web development to machine learning, we’ve got you covered.
                        </p>
                        <button className="btn btn-danger">
                            <i className="fa fa-arrow-right"></i> Explore More
                        </button>
                    </div>
                </div>
            </div>

            <div className="jumbotron py-5 feature-container mt-1">
                <div className="row">
                    <div className="col-md-7 p-5">
                        <h2 className="text-danger"><i className="fa fa-desktop"></i> Book Your Free Trial Class Today!</h2>
                        <p>
                            Discover the benefits of coding with our free trial class. Gain hands-on experience, learn from expert instructors, and start your journey toward becoming a coding professional.
                        </p>
                        <button className="btn btn-danger">Get Started</button>
                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-header bg-danger text-white">
                                <i className="fa fa-envelope"></i> Receive Free Courses
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="form-label"><i className="fa fa-user-circle"></i> Name</label>
                                        <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label for="email" className="form-label"><i className="fa fa-envelope"></i> Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="Email Address" />
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label for="phone" className="form-label"><i className="fa fa-phone"></i> Phone Number</label>
                                        <input type="tel" className="form-control" id="phone" placeholder="Phone Number" />
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label for="subject" className="form-label"><i className="fa fa-pencil"></i> Subject</label>
                                        <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                    </div>
                                
                                    <button type="submit" className="btn btn-danger w-100">Apply Now →</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-center my-5 p-5">
                <h2 className="expertise-heading">
                    Top Languages Training Providing <span className="highlighted-text">Codererdz.online</span> to build in-demand career skills in software development.
                </h2>

                <div id="iconsCarousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="d-flex justify-content-around">
                                <div className="icon"><img src="images/logos/angular.png" alt="HTML Icon" /></div>
                                <div className="icon"><img src="images/logos/react.png" alt="CSS Icon" /></div>
                                <div className="icon"><img src="images/logos/vue.png" alt="JavaScript Icon" /></div>
                                <div className="icon"><img src="images/logos/php.png" alt="React Icon" /></div>
                                <div className="icon"><img src="images/logos/node.png" alt="Python Icon" /></div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="d-flex justify-content-around">
                                <div className="icon"><img src="images/logos/python.png" alt="PHP Icon" /></div>
                                <div className="icon"><img src="images/logos/js.png" alt="Java Icon" /></div>
                                <div className="icon"><img src="images/logos/css.png" alt="Node.js Icon" /></div>
                                <div className="icon"><img src="images/logos/html.png" alt="Angular Icon" /></div>
                                <div className="icon"><img src="images/logos/laravel.png" alt="SQL Icon" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
