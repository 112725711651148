import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Programs = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state

    const limitWords = (text, wordLimit) => {
        const words = text.split(" ");
        return words.length > wordLimit
            ? words.slice(0, wordLimit).join(" ") + "..."
            : text;
    };

    useEffect(() => {
        // Fetch data from API
        const fetchCourses = async () => {
            try {
                const response = await fetch("https://admin.coderecordz.online/api/courses");
                const data = await response.json();
                setCourses(data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error("Error fetching courses:", error);
                setLoading(false); // Stop loading if there’s an error
            }
        };

        fetchCourses();
    }, []);

    return (
        <div>
            <div className="jumbotron bg-danger">
                <div className="container-fluid text-white p-5">
                    <h2><i className="fa fa-bank"></i> Courses</h2>
                    <p>
                        <i className="fa fa-info-circle me-2"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus totam accusamus aliquid deserunt...
                    </p>
                </div>
            </div>

            <div className="container-fluid my-4">
                <div className="row">
                    {loading ? (
                        <div className="d-flex justify-content-center my-5">
                            <div className="spinner-border text-danger" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        courses.map((course) => (
                            <div key={course.id} className="col-md-4 position-relative course-item">
                                <div className="course-grid card shadow-sm">
                                    <img src={ "https://admin.coderecordz.online/storage/app/public/thumbnails/" + course.thumbnail || "https://via.placeholder.com/300x200"} className="card-img-top" alt={course.title} />
                                    <div className="card-body">
                                        <h6><i className="fa fa-book me-1"></i>{course.title}</h6>
                                        <p><i className="fa fa-paragraph me-1"></i>{limitWords(course.description, 8)}</p>
                                    </div>
                                    <div className="card-footer">
                                        <span id="fee"> <b className="text-danger"> <i className="fa fa-credit-card"></i> Fee :</b> <span className="text-decoration-line-through"> {course.fee || "Not Available"} </span> {course.regular_fee || "Not Available"}</span>
                                        <Link to={`http://localhost:3000/course/${course.slug}`} className="btn btn-danger btn-sm float-end">
                                            <i className="fa fa-info-circle"></i> Details
                                        </Link>
                                    </div>
                                </div>
                                <div className="info-box">
                                    <h6 className="text-danger"><i className="fa fa-info-circle me-1"></i><b>Course Details</b></h6>
                                    <p><i className="fa fa-clipboard me-1"></i>{limitWords(course.description, 20)}</p>
                                    <p><b className="text-danger"> <i className="fa fa-play-circle me-1"></i> Lectures:</b> {course.lectures || "Not specified"}</p>
                                    <p><b className="text-danger"><i className="fa fa-folder-open me-1"></i> Sections:</b> {course.sections || "Not specified"}</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default Programs;
